form{
    fieldset{
        border: none;
        background-color: transparent;

        input{
            width: 48%;
            margin-right: 4%;
            margin-bottom: 15px;
            float: left;
            border-bottom: 1px solid $darkbluegrey;
            padding: 5px 0;
            font-weight: 500;

            &:last-child{
                margin-right: 0;
            }

            &[type='submit']{
                background-color: $red;
                display: inline-block;
                width: auto;
                color: $white;
                padding: 10px 40px;
                border-radius: 20px;
                font-weight: 900;
                margin-top: 2em;
                float: none;
                margin-bottom: 0;
            }
        }
        label{
            margin-bottom: 5px;
            display: block;
            font-weight: 500;
        }
        textarea{
            border: 1px solid $darkbluegrey;
        }
        &:after{
            content: '';
            display: block;
            clear: both;
        }

        &.center{
            text-align: center;
        }
    }

    input, button, textarea{
        appearance: none;
        font-weight: 300;
        padding: 5px 10px;
        border: none;
        width: 100%;
        font-size: 1em;
        color: $baseColor;
        border-radius: 0;
        box-shadow: none;
    }
}

form.sidemenu{
    margin-bottom: 2em;
    input{
        width: calc(100% - 30px);
        float: left;
    }
    button{
        width: 30px;
        padding: 5px 0px;
        text-align: center;
        float: left;
        cursor: pointer;
        background-color: $white;
    }
}

form.search{
    margin-bottom: 2em;
    input{
        width: calc(100% - 50px);
        float: left;
        padding: 10px;
        box-shadow:0px 0px 5px 0px rgba(0,0,0,0.15);
    }
    button{
        width: 50px;
        padding: 10px 20px;
        text-align: center;
        float: left;
        cursor: pointer;
        box-shadow:0px 0px 5px 0px rgba(0,0,0,0.15);
        position: relative;

        &:before{
            content: '';
            width: 3px;
            height: 100%;
            position: absolute;
            left: -3px;
            top: 0;
            background-color: $white;
        }
    }
}



@media screen and(max-width: 550px){
    form{
        fieldset{
            input{
                width: 100%;
                margin-right: 0%;
            }
        }
    }
}
