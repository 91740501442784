#error{
    padding: calc(1.5rem + 20px) !important;
    min-height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .intro{
        text-align: center;
        h1{
            font-size: 2em;
            text-transform: uppercase;
        }
        p{
            margin-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(0,0,0,0.07);
            margin-bottom: 20px;
            font-size: 1.2em;
        }
        a{
            padding: 10px 20px;
            background: black;
            color: white;
            display: inline-block;
            border: 1px solid black;
            cursor: pointer;
            border-radius: 20px;
            &:hover{
                color: black;
                background: transparent;
            }
        }
    }
}


@media screen and(max-width: 550px){
    #error {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
