// Base colors
$baseColor: #45535A;
$black: #000000;
$white: #ffffff;
$bluegrey: #54656E;
$darkbluegrey: #45535A;
$red: #CB3201;
$greyback: #F8F8F8;
$blue: #0064BE;
$bordercolor: #CDD4DB;
$darkgrey: #252C31;
$grey: #c2c2c2;


$blue1: #7fb1de;
$blue2: #66a2d8;
$blue3: #4c92d1;
$blue4: #3383cb;
$blue5: #1973c4;
