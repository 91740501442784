nav.navigation{
    padding: 1rem;
    border-bottom: 1px solid $bordercolor !important;
    background-color: $white;
    min-height: 0px !important;
    margin-bottom: 0px !important;
    flex-wrap: nowrap!important;
    position: relative;

    &.sticky-top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1020;
        max-height: 100%;
        overflow: auto;
    }

    .logo {
        width: 250px;
        height: auto;
        padding: 0;
        margin-right: 1rem;
        font-size: 1.125rem;
        line-height: inherit;
        white-space: nowrap;
        float: left;
    }

    .talen{
        margin-bottom: 1em;
        position: absolute;
        right: 1em;
        top: 1em;
        display: none;
        visibility: hidden;

        .wpml-ls-legacy-list-horizontal{
            padding: 0;
            border: none;
            a{
                transition: all 0s;
            }
        }
        ul {
            display: inline-block;
            float: right;

            li{
                position: relative;
                &:after{
                    content: '/';
                    position: absolute;
                    right: -4px;
                    top: 5px;
                    color: $baseColor;
                }
                &:last-child{
                    a{
                        padding-right: 0;
                    }
                    &:after{
                        display: none;
                    }
                }
                &.wpml-ls-current-language{
                    color: $red;
                }
            }
        }
        &:after{
            content: '';
            display: block;
            clear: both;
        }
    }

    .menuwrapper{
        width: calc(100% - 350px);
        display: block;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        float: right;

        ul{
            list-style-type: none;
            width: 100%;
            display: block;
            li{
                width: calc(100% / 6 - 8px);
                margin-right: 8px;
                float: left;
                position: relative;
                background-color: $blue;
                border-top: 2px solid $white;
                border-bottom: 2px solid $white;
                color: $white;
                position: relative;
                visibility: hidden;

                a{
                    font-size: 1em;
                    padding: 20px 0px 20px 26px;
                    transition: all 0s;
                    word-wrap: break-word;
                    hyphens: auto;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    &:before{
                        content: '';
                        position: absolute;
                        display: block;
                        left: 0px;
                        top: -2%;
                        width: 22px;
                        height: 105%;
                        background: $blue;
                        clip-path: polygon(0 0, 0% 100%, 100% 50%);
                        opacity: 0;
                    }
                    &:after{
                        content: '';
                        position: absolute;
                        display: block;
                        right: -19.5px;
                        top: 0px;
                        width: 20px;
                        height: 100%;
                        background: $blue;
                        clip-path: polygon(0 0, 0% 100%, 100% 50%);
                        z-index: 9;
                    }
                }

                &:before{
                    content: '';
                    position: absolute;
                    display: block;
                    left: -1px;
                    top: 0;
                    width: 20px;
                    height: 100%;
                    background: $white;
                    clip-path: polygon(0 0, 0% 100%, 100% 50%);
                    z-index: 1;
                }
                &:after{
                    content: '';
                    position: absolute;
                    display: block;
                    right: -22px;
                    top: -3%;
                    width: 22px;
                    height: 105%;
                    background: $blue;
                    clip-path: polygon(0 0, 0% 100%, 100% 50%);
                    opacity: 0;
                    z-index: 8;
                }

                &:nth-child(1){
                    background: $blue1;
                    a{
                        &:before{ background: $blue1; }
                        &:after{ background: $blue1; }
                    }
                }
                &:nth-child(2){
                    background: $blue2;
                    a{
                        &:before{ background: $blue2; }
                        &:after{ background: $blue2; }
                    }
                }
                &:nth-child(3){
                    background: $blue3;
                    a{
                        &:before{ background: $blue3; }
                        &:after{ background: $blue3; }
                    }
                }
                &:nth-child(4){
                    background: $blue4;
                    a{
                        &:before{ background: $blue4; }
                        &:after{ background: $blue4; }
                    }
                }
                &:nth-child(5){
                    background: $blue5;
                    a{
                        &:before{ background: $blue5; }
                        &:after{ background: $blue5; }
                    }
                }


                &.current-menu-item, &:hover{
                    color: $blue;
                    border-top: 2px solid $blue;
                    border-bottom: 2px solid $blue;
                    background-color: $white;

                    &:after{
                        opacity: 1;
                    }
                    a{
                        &:before{
                            opacity: 1;
                        }
                        &:after{
                            background: $white;
                        }
                    }
                    &:nth-child(1){
                        border-top: 2px solid $blue1;
                        border-bottom: 2px solid $blue1;
                        &:after{
                            background-color: $blue1;
                        }
                    }
                    &:nth-child(2){
                        border-top: 2px solid $blue2;
                        border-bottom: 2px solid $blue2;
                        &:after{
                            background-color: $blue2;
                        }
                    }
                    &:nth-child(3){
                        border-top: 2px solid $blue3;
                        border-bottom: 2px solid $blue3;
                        &:after{
                            background-color: $blue3;
                        }
                    }
                    &:nth-child(4){
                        border-top: 2px solid $blue4;
                        border-bottom: 2px solid $blue4;
                        &:after{
                            background-color: $blue4;
                        }
                    }
                    &:nth-child(5){
                        border-top: 2px solid $blue5;
                        border-bottom: 2px solid $blue5;
                        &:after{
                            background-color: $blue5;
                        }
                    }
                }
            }

            &:after{
                content: '';
                display: block;
                clear: both;
            }
        }
    }
}



.menuresponsive{
    visibility: hidden;
    overflow: hidden;
    height: 0;
    width: 100%;
    background-color: $white;

    .menuwrapper{
        padding-top: 3rem;
        display: block !important;
        width: calc(100% - 15px) !important;
        float: none !important;
        display: block !important;
        ul{
            li{
                width: calc(100% / 3 - 8px) !important;
                margin-bottom: 4px !important;
                a{
                    padding: 10px 0px 10px 26px !important;
                }
            }
        }
    }
    .sidemenu{
        margin-top: 2em;
        ul{
            width: 100%;
            list-style-type: none;
            display: block;
            position: relative;
            li{
                text-align: center;
                a{
                    height: auto !important;
                    padding: 0.7em 0;
                    display: inline-block;
                    position: relative;
                    margin: 0 auto;
                    max-width: 300px;
                    text-align: center;
                    img{
                        float: left;
                        width: 18px;
                        margin-right: 12px;
                    }
                    &:after{
                        content: '';
                        clear: both;
                        display: block;
                    }
                    &:hover{
                        position: relative;
                        &:before{
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: 5px;
                            width: 100%;
                            height: 3px;
                            background-color: $red;
                        }
                    }
                }
                &.current-menu-item{
                    a{
                        position: relative;
                        &:before{
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: 5px;
                            width: 100%;
                            height: 3px;
                            background-color: $red;
                        }
                    }
                }


                &:last-child{
                    a{
                        background-color: $red;
                        display: inline-block;
                        color: $white;
                        text-transform: uppercase;
                        padding: 5px 30px;
                        border-radius: 20px;
                        font-weight: 900;
                        margin-top: 1em;
                        span{
                            width: 100%;
                        }
                    }
                    &.current-menu-item{
                        a{
                            background-color: darken($red, 10%);
                            &:before{
                                display: none;
                            }
                        }
                    }
                    &{
                        a:hover{
                            background-color: darken($red, 10%);
                            &:before{
                                display: none;
                            }
                        }
                    }
                }
            }

            &:after{
                content: '';
                clear: both;
                display: block;
            }
        }
    }
    form.sidemenu{
        margin-top: 3em;
        margin-bottom: 2rem;
        box-shadow:0px 0px 5px 0px rgba(0,0,0,0.15);
        width: calc(100% - 4px);
        margin-left: 2px;
    }
}





@media screen and(max-width: 1350px){
    nav.navigation{
        & > .menuwrapper{
            width: calc(100% - 270px);
        }
    }
}
@media screen and(min-width: 1200px){
    nav.navigation{
        min-height: 113px;
        .logo {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
        }
        & > .menuwrapper{
            display: block !important;
        }
    }
    .menuresponsive{
        display: none !important;
    }
}
@media screen and(max-width: 1200px){
    nav.navigation{
        & > .talen{
            display: block;
        }
        & > .menuwrapper{
            display: none;
        }
    }
}
@media screen and(max-width: 768px){
    .menuresponsive{
        .menuwrapper{
            ul{
                li{
                    width: calc(100% / 2 - 8px) !important;
                }
            }
        }
    }
}
@media screen and(max-width: 550px){
    .menuresponsive{
        .menuwrapper{
            ul{
                li{
                    width: calc(100% - 8px) !important;
                }
            }
        }
    }
}
