#contact{
    padding: calc(1.5rem + 20px) !important;

    .left{
        width: 50%;
        float: left;

        form{
            background-color: $white;
            padding: 40px;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
        }
    }
    .right{
        width: 45%;
        float: right;
    }
    .left, .right{
        ul, ol{
            padding-left: 30px;
            margin-bottom: 1em;
        }
    }
}




@media screen and(max-width: 900px){
    #contact{
        .left{
            width: 100%;
            float: left;
        }
        .right{
            width: 100%;
            float: left;
            margin-bottom: 3em;
        }
    }
}
@media screen and(max-width: 550px){
    #contact {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
