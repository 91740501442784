#text{
    padding: calc(1.5rem + 20px) !important;

    .left{
        width: 46%;
        float: left;
    }
    .right{
        width: 46%;
        float: right;
    }
    .block{
        background-color: $white;
        padding: 40px;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
        margin-top: 3em;
    }
    .left, .right, .block{
        ul, ol{
            padding-left: 30px;
            margin-bottom: 1em;
        }
        a{
            background-color: #CB3201;
            display: inline-block;
            color: #fff;
            padding: 10px 40px;
            border-radius: 20px;
            font-weight: 900;
            &:hover{
                background-color: darken($red, 10%);
            }
        }
    }
}


#text.single_attachment{
    .image{
        width: 46%;
        float: left;
    }
    .content{
        width: 46%;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}



@media screen and(max-width: 900px){
    #text{
        .left{
            width: 100%;
            float: left;
            margin-bottom: 1em;
        }
        .right{
            width: 100%;
            float: left;
        }
    }
}
@media screen and(max-width: 768px){
    #text.single_attachment{
        .image{
            width: 100%;
            float: left;
            margin-bottom: 1em;
        }
        .content{
            width: 100%;
            float: left;
            display: block;
        }
    }
}
@media screen and(max-width: 550px){
    #text {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
