#home{
    padding: calc(1.5rem) !important;

    .slidercontainer{
        position: relative;

        .filter{
            background-color: rgba($black, 0.5);
            width: 100%;
            color: $white;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;

            a{
                display: inline-block;
                padding: 20px 60px;
                transition: color 0s;
                text-transform: uppercase;
                position: relative;
                font-weight: 500;

                &.active{
                    color: $red;
                    font-weight: 700;
                }
                &.locked{
                    color: $red;
                    padding: 20px 60px 20px 70px;
                    font-weight: 700;
                    &:before{
                        content: "\f023";
                        font-family: FontAwesome;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: inherit;
                        color: $red;
                        position: absolute;
                        left: 40px;
                        top: 21px;
                    }
                }
            }
            .arrow{
                cursor: pointer;
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
            }
            .arrow.down {
                left: auto;
                right: 25px;
            }
            .slick-list.draggable {
                width: calc(100% - 120px);
                margin: 0 auto;
                padding: 0 !important;
            }
        }
        .slick {
            .slide {
                width: 100%;
                height: 100%;
                min-height: 700px;
                position: relative;

                .image{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: $grey;
                }
                .textcontent{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: none;
                }
            }

            .arrow{
                cursor: pointer;
                position: absolute;
                left: calc(50% - 25px);
                top: 70px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: rgba($black, 0.5);
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 100;

                img{
                    width: 25px;
                    margin-top: -3px;
                }
            }
            .arrow.down {
                top: auto;
                bottom: 20px;

                img{
                    margin-top: 3px;
                }
                &.behind{
                    z-index: -1 !important;
                }
            }
        }

        .pause{
            cursor: pointer;
            position: absolute;
            left: 20px;
            bottom: 80px;
            width: 45px;
            height: 45px;
            z-index: 100;
            background-image: url('images/pausebutton.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            &.open{
                background-image: url('images/playbutton.png');
            }
        }
        .infobutton{
            cursor: pointer;
            position: absolute;
            left: 20px;
            bottom: 25px;
            width: 45px;
            height: 45px;
            z-index: 200;
            background-image: url('images/infobutton.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            &.active{
                background-image: url('images/infobutton_close.png');
            }
            &.empty{
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
        .text {
            color: #ffffff;
            font-size: 1em;
            padding: 20px 40px 15px 20px;
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: rgba($black, 0.7);
            z-index: 100;
            line-height: 130%;
            min-height: 90px;
            display: none;

            .content{
                width: calc(100% - 65px);
                padding-top: 15px;
                float: right;
            }
            .close{
                position: absolute;
                top: 10px;
                right: 20px;
                cursor: pointer;
            }
        }
    }
}
