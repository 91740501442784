

#trigger{
    display: none;
    position: absolute;
    right: 1em;
    width: 20px;
    height: 15px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    margin-left: 50px;
    color: $baseColor;
    margin-top: 3.5rem;
    .tekst{
        position: absolute;
        left: -50px;
        text-transform: uppercase;
        font-size: 1em;
        top: -3px;
        margin-left: -0.5em;
  }
  span{
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      margin-top: 1px;
      background: $baseColor;
  }
  span:nth-child(2) {
      top: 0px;
  }
  span:nth-child(3), span:nth-child(4) {
      top: 5px;
  }
  span:nth-child(5) {
      top: 10px;
  }
}
#trigger.open{
    span:nth-child(2) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
    span:nth-child(3) {
        transform: rotate(45deg);
    }
    span:nth-child(4) {
        transform: rotate(-45deg);
    }
    span:nth-child(5) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
}



@media screen and(max-width: 1200px){
    #trigger{
        display: block;
    }
}
