#wpadminbar{
    display: none;
}
*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	height: auto;
    outline: none !important;
}
html, body{
	width: 100%;
	height: 100%;
	font-size: 16px;
	margin: 0;
	padding: 0;
    font-family: 'Museo Sans';
    font-weight: 500;
    color: $baseColor;
    background-color: $greyback;
}
.wrapper {
    min-height: 100%; //HIER NOG DE JUISTE HOOGTE INVULLEN
    overflow-x: hidden;
}
a{
	text-decoration: none;
	outline: none;
	transition: all 0.5s;
  	color: inherit;
}
.container{
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
	z-index: 10;
}
p{
  	margin-bottom: 1em;
    &:last-child{
        margin-bottom: 0;
    }
}
.clearing{
	clear: both;
	display: block;
}
img{
    max-width: 100%;
}
.title{
    margin-bottom: 2em;
    padding-bottom: 10px;
    position: relative;

    &:after{
        content: '';
        position: absolute;
        width: 40px;
        height: 5px;
        background-color: $red;
        left: 0;
        bottom: 0;
    }
}


@media screen and(max-width: 1200px){
    .container{
    	width: 98%;
    }
}
