h1{
    font-weight: 900;
    font-size: 1.4em;
    text-transform: uppercase;
}
h2{
    font-weight: 700;
    font-size: 1.1em;
}
h3{
    font-weight: 900;
    font-size: 1.1em;
}
