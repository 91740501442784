#search{
    padding: 20px;

    .searchgrid{
        ul{
            list-style-type: none;
            display: block;
            width: 100%;

            li{
                padding: 2em 0;
                border-bottom: 1px solid $bordercolor;

                h3{
                    margin-bottom: 0.5em;
                }
                a{
                    background-color: $red;
                    display: inline-block;
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 20px;
                    float: right;
                }

                &:first-child{
                    padding-top: 1em;
                }

                .image{
                    width: 100px;
                    float: left;
                }
                .content{
                    width: calc(100% - 120px);
                    float: right;
                }
            }
        }
    }
}
