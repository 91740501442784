main {
    top: 111px;
    width: calc(100% - 300px);
    position: absolute;
    margin-left: 300px !important;
    background-color: $greyback;
    min-height: calc(100% - 111px);
    overflow: auto;
}
.sidebar {
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 0;
    width: 300px;
    border-right: 2px solid #EDEDED;
    border-bottom: none;
    background-color: $bluegrey;
    color: $white;

    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
        height: 100%;
        padding-top: .5rem;
        overflow: auto;
        padding: 0px 0px 50px 0px;
    }

    form{
        padding: 0em 15px;
    }

    .menu{
        ul{
            width: 100%;
            list-style-type: none;
            display: block;
            position: relative;
            padding-bottom: 70px;
            min-height: calc(100% - 150px);
            position: absolute;

            li{
                a{
                    padding: 1em 20px;
                    display: block;
                    position: relative;
                    span{
                        float: right;
                        width: calc(100% - 30px);
                    }
                    img{
                        float: left;
                        width: 18px;
                        filter: brightness(0) invert(1);
                    }
                    &:after{
                        content: '';
                        clear: both;
                        display: block;
                    }
                }

                &.current-menu-item, &:hover{
                    a{
                        background-color: $darkbluegrey;
                        &:before{
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 3px;
                            height: 100%;
                            background-color: $red;
                        }
                    }
                }

                &:last-child{
                    position: absolute;
                    bottom: -15px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: inline-block;

                    a{
                        background-color: $red;
                        display: inline-block;
                        color: $white;
                        text-transform: uppercase;
                        padding: 10px 40px;
                        border-radius: 20px;
                        font-weight: 900;
                        span{
                            width: 100%;
                        }
                    }

                    &.current-menu-item, &:hover{
                        a{
                            background-color: darken($red, 10%);
                            &:before{
                                display: none;
                            }
                        }
                    }
                }
            }

            &:after{
                content: '';
                clear: both;
                display: block;
            }
        }
    }

    .talen{
        margin-bottom: 1em;
        visibility: hidden;

        .wpml-ls-legacy-list-horizontal a{
            transition: all 0s;
        }

        ul {
            display: inline-block;
            float: right;

            li{
                position: relative;
                &:after{
                    content: '/';
                    position: absolute;
                    right: -4px;
                    top: 5px;
                    color: $white;
                }
                &:last-child:after{
                    display: none;
                }
                &.wpml-ls-current-language{
                    color: $red;
                }
            }
        }
        &:after{
            content: '';
            display: block;
            clear: both;
        }
    }
}


@media screen and(max-width: 1200px){
    .sidebar {
        display: none;
    }
    main {
        width: 100%;
        margin-left: 0px !important;
    }
}
