@font-face {
  font-family: 'Museo Sans';
  src: url('fonts/museo-sans-rounded-300.eot');
  src: url('fonts/museo-sans-rounded-300.eot?#iefix') format('embedded-opentype'),
  url('fonts/museo-sans-rounded-300.woff') format('woff'),
  url('fonts/museo-sans-rounded-300.ttf') format('truetype'),
  url('fonts/museo-sans-rounded-300.svg#museo_sans_rounded500') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('fonts/museo-sans-rounded-500.eot');
  src: url('fonts/museo-sans-rounded-500.eot?#iefix') format('embedded-opentype'),
  url('fonts/museo-sans-rounded-500.woff') format('woff'),
  url('fonts/museo-sans-rounded-500.ttf') format('truetype'),
  url('fonts/museo-sans-rounded-500.svg#museo_sans_rounded500') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('fonts/museo-sans-rounded-700.eot');
  src: url('fonts/museo-sans-rounded-700.eot?#iefix') format('embedded-opentype'),
  url('fonts/museo-sans-rounded-700.woff') format('woff'),
  url('fonts/museo-sans-rounded-700.ttf') format('truetype'),
  url('fonts/museo-sans-rounded-700.svg#museo_sans_rounded500') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('fonts/museo-sans-rounded-900.eot');
  src: url('fonts/museo-sans-rounded-900.eot?#iefix') format('embedded-opentype'),
  url('fonts/museo-sans-rounded-900.woff') format('woff'),
  url('fonts/museo-sans-rounded-900.ttf') format('truetype'),
  url('fonts/museo-sans-rounded-900.svg#museo_sans_rounded500') format('svg');
  font-weight: 900;
  font-style: normal;
}
